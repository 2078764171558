<template>
  <div class="newyearad">
    <div class="mark"></div>
    <div class="win">
      <i class="close">x</i>
      <div class="nr">
        <img :src="source.pic" v-if="source.pic">
        <video :src="source.video" v-if="source.video" controls autoplay></video>
      </div>
    </div>
  </div>
</template>
<script>


export default {
    name: 'ad',
    data() {
        return {
            source: {
                video: '',
                pic: ''
            }
        }
    },
    created() {
        if (this.$store.state.articles.ad.length > 0) {
            if (this.$store.state.articles.ad[0].video) {
                this.$set(this.source, 'video', this.$store.state.articles.ad[0].video)
            }
            if (this.$store.state.articles.ad[0].pic) {
                this.$set(this.source, 'pic', this.$store.state.articles.ad[0].pic)
            }
        } else {
            let data = {
                app_key: '1D5AF16FD7D90EE3C5CF70D088249505',
                model_name: 'bgimg',
                perpage: 1,
                where: ["xs=1"]
            }
            this.$store.dispatch('articles/getArticles', data).then((rs) => {
                if (rs[0].video) {
                    this.$set(this.source, 'video', rs[0].video)
                }
                if (rs[0].pic) {
                    this.$set(this.source, 'pic', rs[0].pic)
                }
            })

        }
        // let data = {
        //     app_key: '1D5AF16FD7D90EE3C5CF70D088249505',
        //     model_name: 'bgimg',
        //     perpage: 1,
        //     where: ["xs=1"]
        //
        // }
        // list(data).then((rs) => {
        //     let data = rs.data.list[0]
        //     if (data.video) {
        //         this.$set(this.source, 'video', data.video)
        //     }
        //     if (data.pic) {
        //         this.$set(this.source, 'pic', data.pic)
        //     }
        //
        // })


    },
    mounted() {
        let newyearad = document.querySelector('.newyearad')
        let close = newyearad.querySelector('.close')
        close.addEventListener('click', () => {
            newyearad.style.display = 'none'
        });
        if (getCookie("newYear")) {
            newyearad.style.display = 'none'
        } else {
            newyearad.style.display = 'block'
            setCookie("newYear", "newYear", 1);
        }

        function setCookie(cname, cvalue, exdays) {
            let d = new Date();
            d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
            let expires = "expires=" + d.toUTCString();
            document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
        }

        function getCookie(cname) {
            let name = cname + "=";
            let allCookie = document.cookie
            let ca = allCookie.split(';')
            let el, result;
            ca.map(function (elem) {
                el = elem.replace(/\s+/g, "");
                if (el.indexOf(name) == 0) {
                    result = el.substring(name.length, el.length);
                }
            })
            return result
        }


    },
    methods: {},
    computed: {}
}
</script>
<style>
.newyearad {
  position: fixed;
  z-index: 999;
  color: #b20b1d;
}

.newyearad .mark {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, .7);
  left: 0;
  top: 0;
}

.newyearad .win {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 15%
}

.newyearad .win .nr {
  width: 100%;
  height: 100%;
}


.newyearad .win .nr img {
  max-width: 500px;
  width: 100%;
  border-radius: 10rem;
}


.newyearad .win .close {
  position: absolute;
  font-style: normal;
  width: 36px;
  height: 36px;
  border: solid 1px #eddfae;
  border-radius: 100%;
  right: -18px;
  top: -18px;
  cursor: pointer;
  font-size: 32px;
  line-height: 24px;
  text-align: center;
  color: #eddfae;
}

.newyearad .win .nr .text {
  font-size: 14px;
  padding: 35px;
  line-height: 24px;
}

@media (max-width: 500px) {
  .newyearad .win {
    width: 50%;
  }

  .newyearad .win .close {
    width: 26px;
    height: 26px;
    right: -13px;
    top: -13px;
    font-size: 22px;
    line-height: 19px;
  }


}
</style>

