<template>
  <div class="web" :class="{index:index}">
    <div class="navbar" v-if="!index">
      <div class="sidebar-button" @click="expdown=!expdown">
        <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" viewBox="0 0 448 512" class="icon">
          <path fill="currentColor"
                d="M436 124H12c-6.627 0-12-5.373-12-12V80c0-6.627 5.373-12 12-12h424c6.627 0 12 5.373 12 12v32c0 6.627-5.373 12-12 12zm0 160H12c-6.627 0-12-5.373-12-12v-32c0-6.627 5.373-12 12-12h424c6.627 0 12 5.373 12 12v32c0 6.627-5.373 12-12 12zm0 160H12c-6.627 0-12-5.373-12-12v-32c0-6.627 5.373-12 12-12h424c6.627 0 12 5.373 12 12v32c0 6.627-5.373 12-12 12z"></path>
        </svg>
      </div>
      <router-link to="/" tag="a" class="home-link"></router-link>
      <div class="searcharea">
        <div class="search-box">
          <input autocomplete="off" v-model="keyword">
          <ul class="suggestions" v-if="suggestList.length>0">
            <li v-for="(item,xh) in suggestList" :key="xh">
              <router-link v-if="currentPath!='webs'" :to="'/'+currentPath+'/'+item.id" tag="a">
                {{xh+1}}、{{item.title}}
              </router-link>
              <a target="_blank" v-else :href=item.url>{{xh+1}}、{{item.title}}</a>
            </li>
          </ul>
        </div>
        <nav class="nav-links" v-show="expdown">
          <div class="nav-item">
            <router-link to="/articles" tag="a">文章</router-link>
          </div>
          <div class="nav-item">
            <router-link to="/teachs" tag="a">前端</router-link>
          </div>
          <div class="nav-item">
            <router-link to="/works" tag="a">作品</router-link>
          </div>
          <div class="nav-item">
            <router-link to="/webs" tag="a">网站</router-link>
          </div>
          <div class="nav-item">
            <router-link to="/lifes" tag="a">生活</router-link>
          </div>
          <div class="nav-item">
            <router-link to="/mottos" tag="a">格言</router-link>
          </div>
          <div class="nav-item">
            <router-link to="/about" tag="a">关于</router-link>
          </div>
        </nav>
      </div>
    </div>
    <header v-if="index">
      <div class="logo">
        <router-link to="/" tag="a"></router-link>
      </div>
      <nav>
        <ul>
          <router-link to="/articles" tag="li">文章</router-link>
          <router-link to="/teachs" tag="li">前端</router-link>
          <router-link to="/works" tag="li">作品</router-link>
          <router-link to="/webs" tag="li">网站</router-link>
          <router-link to="/lifes" tag="li">生活</router-link>
          <router-link to="/mottos" tag="li">格言</router-link>
          <router-link to="/about" tag="li">关于</router-link>
        </ul>
      </nav>
    </header>
    <router-view :expleft="expleft" :expup.sync="expup" :dataList="currentData"></router-view>
    <ul class="rightNav" v-if="detail">
      <li class="iconfont icon-menu pc" v-if="detail" @click="navleft"></li>
      <li class="iconfont icon-menu mobile" v-if="detail" @click="navup"></li>
      <li class="iconfont icon-l-goToTop" @click="totop"></li>
    </ul>
    <ad></ad>
    <audio preload="auto" :src="mlist[mxh].src"
           style="display: none;" ref="music"></audio>
    <div class="mico" @click="toggle" :class="{gb:!isPlay}">
      <div class="ic"><i></i></div>
      <ul class="mlist">
        <li v-for="(item,xh) in mlist" :key="xh" @click.stop="mxh=xh" :class="{cur:mxh===xh}">{{item.name}}</li>
      </ul>
    </div>
  </div>
</template>
<script>
import ad from '@/views/ad'
import '@/assets/css/lee.css'
import '@/assets/css/lee-mb.css'
import MiniSearch from 'minisearch'
import ball from '@/utils/ball'
//import {list} from '@/api/api'

export default {
    name: 'myweb',
    components: {
        ad
    },
    data() {
        return {
            currentPath: '',//用来连接地址
            currentData: [],//当前连接地址对应数据，搜索根据这个数据来查询
            keyword: '',
            suggestList: [],//搜索列表
            expdown: true,//这个是移动端菜单是否显示
            index: false,
            detail: false,//是不是详细页
            expleft: true,//详细页PC端那个导航关闭与否
            expup: false,//详细页移动端那个导航关闭与否
            isPlay: false,//是否播放声音
            mlist: [{
                name: '光亮',
                src: 'gl.mp3'
            }, {
                name: '有梦好甜蜜',
                src: 'ymhtm.mp3'
            }, {
                name: '红梅花儿开',
                src: 'hmhek.mp3'
            }],
            mxh: 0
        }
    },
    mounted() {
        let sw = window.innerWidth
        let _this = this
        cl(sw)
        window.addEventListener('resize', () => {
            sw = window.innerWidth
            cl(sw)
        })

        function cl(sw) {
            if (sw > 768) {
                _this.expleft = true
                _this.expup = false
                _this.expdown = true
            } else {
                _this.expleft = false
                _this.expup = false
                _this.expdown = false
            }
        }

        //统计代码
        var _hmt = _hmt || [];
        (function () {
            var hm = document.createElement("script");
            hm.src = "https://hm.baidu.com/hm.js?3384d95e63352dd4d880e5b0aa3b3f9a";
            var s = document.getElementsByTagName("script")[0];
            s.parentNode.insertBefore(hm, s);
        })();
        //


    },
    methods: {
        totop() {
            //dfsfdf
            document.documentElement.scrollIntoView({behavior: "smooth", block: "start"});
        },
        navleft() {
            this.expleft = !this.expleft
        },
        navup() {
            this.expup = !this.expup
        },
        toggle() {
            this.isPlay = !this.isPlay
        },
        paopao() {

        }
    },
    computed: {},
    watch: {
        mxh: {
            immediate: false,
            handler() {
                this.$refs.music.pause()
                setTimeout(() => {
                    this.$refs.music.play()
                }, 500)

            }
        },
        isPlay: {
            immediate: false,
            handler(value) {
                //let _this = this

                function paopao() {
                    let ba = Array.from(document.querySelectorAll('.yf'));
                    if (ba.length >= 4) {
                        return
                    }

                    let balls = new ball(25, window.innerHeight - 35);
                    balls.dong();
                }


                if (value) {
                    this.$refs.music.play()
                     this.pp=setInterval(() => {
                         paopao()
                    }, 500)

                } else {
                    clearInterval(this.pp)
                    this.$refs.music.pause()

                }
            }
        },
        isPaopao: {
            immediate: true,
            handler(value) {
                if (value) {
                    if (this.st) {
                        clearInterval(this.st)
                        this.st = setInterval(() => {
                            let balls = new ball(25, window.innerHeight - 35);
                            balls.dong();
                        }, 500)
                    } else {
                        this.st = setInterval(() => {
                            let balls = new ball(25, window.innerHeight - 35);
                            console.log(balls)
                            balls.dong();
                        }, 500)
                    }

                } else {
                    clearInterval(this.st)
                }
            }
        },
        keyword: {
            immediate: true,
            handler(value) {
                let miniSearch = new MiniSearch({
                    fields: ['title'],
                    storeFields: ['title', 'url'],
                    searchOptions: {
                        fuzzy: 16
                    }
                })
                miniSearch.addAll(this.currentData)
                value = value.toLowerCase()
                let result = miniSearch.search(value, {
                    filter: (result) => result.title.toLowerCase().indexOf(value) > -1
                })
                this.suggestList = result
            }
        },
        $route(to) {
            this.expup = false
            this.keyword = ''
            this.suggestList = []
            let sw = window.innerWidth
            if (sw > 768) {
                this.expdown = true
            } else {
                this.expdown = false
            }
            this.expleft = true
            if (to.fullPath === '/') {
                this.index = true
            } else {
                this.index = false
            }
            if (to.meta.detail) {
                this.detail = true
            } else {
                this.detail = false
            }
            //
            let apiUrl;
            if (to.path.length > 1) {
                if (to.path.indexOf('articles') > -1) {
                    apiUrl = 'article'
                    this.currentPath = 'articles'
                } else if (to.path.indexOf('teachs') > -1) {
                    apiUrl = 'teachs'
                    this.currentPath = 'teachs'
                } else if (to.path.indexOf('lifes') > -1) {
                    apiUrl = 'life'
                    this.currentPath = 'lifes'
                } else if (to.path.indexOf('webs') > -1) {
                    apiUrl = 'web'
                    this.currentPath = 'webs'
                } else if (to.path.indexOf('works') > -1) {
                    apiUrl = 'works'
                    this.currentPath = 'works'
                } else {
                    apiUrl = 'article'
                    this.currentPath = 'articles'
                }
            }
            if (this.currentPath) {
                if (this.$store.state.articles[this.currentPath].length > 0) {
                    this.currentData = this.$store.state.articles[this.currentPath]
                } else {
                    let formData = {
                        app_key: '1D5AF16FD7D90EE3C5CF70D088249505',
                        model_name: apiUrl,
                        perpage: 1000,
                        order: ["mtime DESC", "id DESC"]
                    }
                    let formDataWB = {
                        app_key: '1D5AF16FD7D90EE3C5CF70D088249505',
                        model_name: apiUrl,
                        perpage: 1000,
                        order: ["id DESC"]
                    }
                    if (this.currentPath === 'articles') {
                        this.$store.dispatch('articles/getArticles', formData).then((rs) => {
                            this.currentData = rs
                        })
                    } else if (this.currentPath === 'teachs') {
                        this.$store.dispatch('articles/getArticles', formData).then((rs) => {
                            this.currentData = rs
                        })
                    } else if (this.currentPath === 'lifes') {
                        this.$store.dispatch('articles/getArticles', formData).then((rs) => {
                            this.currentData = rs
                        })
                    } else if (this.currentPath === 'webs') {
                        this.$store.dispatch('articles/getArticles', formDataWB).then((rs) => {
                            this.currentData = rs
                        })
                    } else if (this.currentPath === 'works') {
                        this.$store.dispatch('articles/getArticles', formDataWB).then((rs) => {
                            this.currentData = rs
                        })
                    } else {
                        this.$store.dispatch('articles/getArticles', formData).then((rs) => {
                            this.currentData = rs
                        })
                    }

                }
            }

        }
    }
}
</script>
