import request from '@/utils/request'
import qs from "qs";

export function list(data) {
  data=qs.stringify(data)
  return request({
    url: '/FreeQuery',
    method: 'POST',
    data
  })
}
export function detail(data) {
  data=qs.stringify(data)
  return request({
    url: '/Get',
    method: 'POST',
    data
  })
}
export function CheckCreate(data) {
  data=qs.stringify(data)
  return request({
    url: '/CheckCreate',
    method: 'POST',
    data
  })
}


// export function getInfo(params) {
//   return request({
//     url: '/user',
//     method: 'get',
//     params
//   })
// }
