import router from '@/router'
import getPageTitle from '@/utils/getPageTitle'

router.afterEach((to) => {
    if (to.params.id) {
        document.title = getPageTitle(to.meta.title + '-' + to.params.id)
    } else {
        document.title = getPageTitle(to.meta.title)
    }
})
