import Vue from 'vue'
import LeeToast from './LeeToast.vue'

const $Toast = (option) => {
    const toastConstructor = Vue.extend(LeeToast)
    const toastInstance = new toastConstructor();
    toastInstance.$mount(document.createElement('div'))
    document.body.appendChild(toastInstance.$el)
    //默认参数
    let setOption = {
        message: toastInstance.message,
        type: toastInstance.type,
        duration: toastInstance.duration,
        onClose: () => {
        }
    }
    const myOption = Object.assign(setOption, option)
    toastInstance.value = true
    toastInstance.type = myOption.type
    toastInstance.message = myOption.message
    toastInstance.duration = myOption.duration
    setTimeout(() => {
        toastInstance.value = false
        myOption.onClose()
    }, myOption.duration)


}
export default $Toast

