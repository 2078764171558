import {list} from '@/api/api'

const state = {
    bgimg: [],
    articles: [],//ok
    hotArticles: [],//ok
    lastArticle: [],
    teachs: [],//ok
    hotTeachs: [],//ok
    lifes: [],//ok
    hotLifes: [],//ok
    lastLife: [],
    webs: [],//ok
    webType: [],//ok
    works: [],//ok
    ad: [],
    about: [],//ok
}

const mutations = {
    getBgimg: (state, rs) => {
        state.bgimg = rs
    },
    getAd: (state, rs) => {
        state.ad = rs
    },
    getArticles: (state, rs) => {
        state.articles = rs
    },
    getHotArticles: (state, rs) => {
        state.hotArticles = rs
    },
    getLastArticles: (state, rs) => {
        state.lastArticles = rs
    },
    getTeachs: (state, rs) => {
        state.teachs = rs
    },
    getHotTeachs: (state, rs) => {
        state.hotTeachs = rs
    },
    getLifes: (state, rs) => {
        state.lifes = rs
    },
    getHotLifes: (state, rs) => {
        state.hotLifes = rs
    },
    getLastLifes: (state, rs) => {
        state.lastLifes = rs
    },
    getWebType: (state, rs) => {
        state.webType = rs
    },
    getWebs: (state, rs) => {
        state.webs = rs
    },
    getWorks: (state, rs) => {
        state.works = rs
    },
    getAbout: (state, rs) => {
        state.about = rs
    },

}

const actions = {
    getArticles({commit}, sendData) {
        let model_name = sendData.model_name
        let where = sendData.where
        let perpage = sendData.perpage
        return new Promise((resolve, reject) => {
            list(sendData).then((rs) => {
                const {data} = rs
                if (model_name === 'article') {
                    if (where) {
                        commit('getHotArticles', data.list)
                    } else if (perpage === 1) {
                        commit('getLastArticles', data.list)
                    } else {
                        commit('getArticles', data.list)
                    }
                } else if (model_name === 'teachs') {
                    if (where) {
                        commit('getHotTeachs', data.list)
                    } else {
                        commit('getTeachs', data.list)
                    }
                } else if (model_name === 'life') {
                    if (where) {
                        commit('getHotLifes', data.list)
                    } else if (perpage === 1) {
                        commit('getLastLifes', data.list)
                    } else {
                        commit('getLifes', data.list)
                    }
                }else if (model_name === 'webtype') {
                    commit('getWebType', data.list)
                }else if (model_name === 'web') {
                    commit('getWebs', data.list)
                }else if (model_name === 'works') {
                    commit('getWorks', data.list)
                }else if (model_name === 'about') {
                    commit('getAbout', data.list)
                }else if (model_name === 'bgimg') {
                    let xs=where[0].split('=')[1]
                    if(xs==1){
                        commit('getAd', data.list)
                    }else{
                        commit('getBgimg', data.list)
                    }

                }
                resolve(data.list)
            }).catch(error => {
                reject(error)
            })
        })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}

