import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const routes = [
    {
        path: '/',
        component: () => import('@/views/index'),
        meta: {title: '首页'}
    },
    {
        path: '/articles',
        component: () => import('@/views/articles'),
        meta: {title: '文章'}
    },
    {
        path: '/teachs',
        component: () => import('@/views/teachs'),
        meta: {title: '前端'}
    },
    {
        path: '/mottos',
        component: () => import('@/views/mottos'),
        meta: {title: '格言'}
    },
    {
        path: '/articles/:id',
        component: () => import('@/views/articlesdetail'),
        meta: {title: '文章详情', detail: true}
    },
    {
        path: '/teachs/:id',
        component: () => import('@/views/teachsdetail'),
        meta: {title: '前端详情', detail: true}
    },
    {
        path: '/works',
        component: () => import('@/views/works'),
        meta: {title: '作品'}
    },
    {
        path: '/webs',
        component: () => import('@/views/webs'),
        meta: {title: '网站收集'}
    },
    {
        path: '/webs/type',
        component: () => import('@/views/webstype'),
        meta: {title: '网站类别管理'}
    },
    {
        path: '/lifes',
        component: () => import('@/views/lifes'),
        meta: {title: '生活'}
    },
    {
        path: '/lifes/:id',
        component: () => import('@/views/lifesdetail'),
        meta: {title: '生活详情', detail: true}
    },
    {
        path: '/about',
        component: () => import('@/views/about'),
        meta: {title: '关于'}
    },
    {
        path: '/404',
        component: () => import('@/views/404'),
        meta: {title: '404'}
    },
    {path: '*', redirect: '/404'}
]

const router = new Router({
    routes,
    linkActiveClass: 'active',
    linkExactActiveClass: 'exact-active',
    scrollBehavior() {
        return { x: 0, y: 0 };
    },
})
export default router
