let per = Math.PI / 180
export default function ball(x, y) {
    this.deg = 0;
    this.target = document.createElement("div");
    this.target.className = "yf";
    this.x = x;
    this.y = y;
    document.body.appendChild(this.target);
}

ball.prototype.dong = function () {
    this.target.classList.add("fadein")
    this.deg += 3;
    let y = -this.deg * per * 20;
    let x = 5 * this.deg * per * Math.cos(this.deg * per);
    if (this.deg >= 350) {
        this.target.classList.remove('fadein')
        this.target.classList.add('fadeout')
        //let sto=setTimeout(() => {
            this.target.remove()
        //}, 200)

    }
    this.target.style.cssText = "left:" + this.x + "px;top:" + this.y + "px;transform: translate(" + x + "px," + y + "px);";

    requestAnimationFrame(this.dong.bind(this));

}